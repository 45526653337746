export function galleryInit() {

    /*const $l = $('.load-more-gallery');

    if(!$l.length) return;

    $l.on('click', (e) => {
        e.preventDefault();

        $.ajax({
            url: '/assets/ajax/gallery.html',
            success: data => {
                $('.section-catalog-block').append(data);
            }
        })
    });*/
}


