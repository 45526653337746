import Swiper from 'swiper';
import {addZero} from "../modules/functions";

export function mainSlider() {
    const $s = $('.main-slider');

    if(!$s.length) return;

    const slider = new Swiper('.main-slider', {
        navigation: {
            nextEl: '.main-slider-next',
            prevEl: '.main-slider-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: (number) => {
                return addZero(number);
            },
            formatFractionTotal: (number) => {
                return addZero(number);
            }
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
        },
        loop: true,
        mousewheel: false,
        autoplay: {
            delay: 4000,
        },
    });

}