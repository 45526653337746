export function catalogfiltersInit() {
    $('.catalog-menu-block-item').on('click', function() {
        if ($(this).hasClass('catalog-menu-block-item-active')) {
            $(this).removeClass('catalog-menu-block-item-active');
            $(this).parent().append(this);
        } else {
            $(this).addClass('catalog-menu-block-item-active');
            $(this).parent().prepend(this);
        }

        let selected = [];
        let type = $(this).data('type');
        $('.catalog-menu-block-item-active').each(function() {
            if ($(this).data('type') == type) {
                selected.push(+$(this).data('id'));
            }
        });

        $('.load-more-catalog').data(type, selected);

        loadProducts();
    });

    $('.catalog-filter-block-item').click(function(){
        if( $(this).attr('class') == 'active' ){
            return false;
        }

        const intent = $(this).data('intent');
        $('.catalog-filter-block-item.active').removeClass('active');
        $(this).addClass('active');

        if (intent) {
            $('.load-more-catalog').data('intent', intent);
        } else {
            $('.load-more-catalog').removeData('intent');
        }

        loadProducts();
    });

    $('.load-more-catalog').on('click', function() {
        $.ajax({
            url: '/catalog.js',
            data: $(this).data(),
        });

        $(this).data('page', $(this).data('page') + 1);
    });

    $('.hide-more-catalog').on('click', function() {
        loadProducts();
    });
}

function loadProducts() {
    $('.load-more-catalog').data('page', 1);

    $.ajax({
        url: '/catalog.js',
        data: $('.load-more-catalog').data(),
    });

    $('.load-more-catalog').data('page', 2);
}