export function sectionTab() {
   /* $('#characteristics').show();

    $('.section-tabs a').click(function(){
        if( $(this).attr('class') == 'active' ){
            return false;
        }

        var link = $(this).attr('href');
        var prevActive = $('a.active').attr('href');
        $('a.active').removeClass('active');
        $(this).addClass('active');

        $(prevActive).fadeOut(500, function(){
            $(link).fadeIn();


        });
        return false;
    });*/
}