import Swiper from 'swiper';
import {addZero} from "../modules/functions";

export function catalogitemsectorSlider() {

    initFancybox();

    const $s = $('.catalog-item-sector-slider');

    if(!$s.length) return;

    const slider = new Swiper('.catalog-item-sector-slider', {
        navigation: {
            nextEl: '.catalog-item-slider-next',
            prevEl: '.catalog-item-slider-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                /*spaceBetween: 20*/
            },
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: (number) => {
                return addZero(number);
            },
            formatFractionTotal: (number) => {
                return addZero(number);
            }
        },
        mousewheel: false
    });
}

function initFancybox() {

    const $g = $('[data-fancybox*="gallery"]');

    if(!$g.length) return;

    $g.fancybox({
        infobar: false,
        smallBtn: true,
        backFocus: false,
        btnTpl: {
            smallBtn:
                '<button type="button" data-fancybox-close class="close-gallery">' +
                '<i class="if if-close"></i>' +
                '</button>',
            arrowLeft:
                '<button data-fancybox-prev class="gallery-prev">' +
                '<i class="if if-arrow-left"></i>' +
                '</button>',

            arrowRight:
                '<button data-fancybox-next class="gallery-next">' +
                '<i class="if if-arrow-right"></i>' +
                '</button>',
        }
    });
}
