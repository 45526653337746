export function initfileupload() {
    $("#new_feedback .add_fields").click(function() {
        setTimeout(initFileUploadEvent, 500);
    });
}

function initFileUploadEvent() {
    $("#new_feedback input[type=file]").change(function() {
        let fileName = $(this)[0].files[0].name;
        $(this).siblings().text(fileName);

        let parentElement = $(this).parent().parent();
        parentElement.siblings('.if-staple').addClass('invisible');
        parentElement.siblings('.if-check').removeClass('invisible');
        parentElement.siblings('.button-delete-file-active').removeClass('invisible');
    });
}
