export function accordionInit() {
    const $v = $('.section-tabs');
    const $it = $v.find('.section-tabs-menu');

    $it.on('click', function () {
        const $c = $(this);


        if($c.is('.transform-icon')) {
            $c.removeClass('transform-icon');
            $('.section-tab-pane').stop(true, true).slideUp(300);
            $('.section-tab-pane-submenu').stop(true, true).slideUp(300);
            $('.section-tabs-menu-submenu').removeClass('transform-icon');

        } else {

            const $ti = $v.find('.section-tabs-menu.transform-icon');

            $('.section-tab-pane').slideUp(300);
            $ti.removeClass('transform-icon');
            $('.section-tab-pane-submenu').stop(true, true).slideUp(300);
            $('.section-tabs-menu-submenu').removeClass('transform-icon');

            $c.addClass('transform-icon');
            $c.next('.section-tab-pane').slideDown(300);
        }
    });
}

export function accordionsubmenuInit() {
    const $v = $('.section-tabs');
    const $it = $v.find('.section-tabs-menu-submenu');

    $it.on('click', function () {
        const $c = $(this);


        if($c.is('.transform-icon')) {
            $c.removeClass('transform-icon');
            $('.section-tab-pane-submenu').stop(true, true).slideUp(300);

        } else {

            const $ti = $v.find('.section-tabs-menu-submenu.transform-icon');

            $('.section-tab-pane-submenu').slideUp(300);
            $ti.removeClass('transform-icon');



            $c.addClass('transform-icon');
            $c.next('.section-tab-pane-submenu').slideDown(300);
        }
    });
}

