import {
    burgerInit, fancyboxOpen, formControl,
    initCounters, initPopup,
    navigationInit, mainSlider, applicationsectorSlider, materialsectorSlider, catalogadditionalmaterialsSlider,
    catalogcharacteristicssectorSlider, maincatalogsectorSlider, catalogitemsectorSlider,
    infrastructuresectorSlider, materialShow
} from './components';
import {filtersInit} from './components/filters';
import {accordionInit} from './components/accordion';
import {accordionsubmenuInit} from './components/accordion';
import {buttonmainInit} from './components/button-main';
import {parametersInit} from './components/parameters';
import {cookies} from './components/cookies';
import {contactMap} from './modules/contact-map';
import {contactMapMobile} from './modules/contact-map';
/*import {contactMapService} from './modules/contact-map';*/
import {sectionTab} from './components/section-tab';
/*import {footerInit} from './components/footer';*/
/*import {headerdesktopInit} from './components/header';*/
import {headerInit} from './components/header';
import {catalogfiltersInit} from './components/catalog-filter';
import {galleryInit} from './components/gallery';
import {environmentalsectorSlider} from './components/environmental-sector-slider';
import {initfileupload} from './components/fileupload';
import {checkboxtenderInit} from './components/checkbox-tender';


$(document).ready(init);

function init() {
    /*headerdesktopInit();*/
    burgerInit();
    navigationInit();
    initCounters();
    formControl();
    fancyboxOpen();
    mainSlider();
    initPopup();
    filtersInit();
    parametersInit();
    contactMap();
    contactMapMobile();
    /*contactMapService();*/
    sectionTab();
    accordionInit();
    /*footerInit();*/
    headerInit();
    applicationsectorSlider();
    materialsectorSlider();
    materialShow();
    catalogadditionalmaterialsSlider();
    catalogcharacteristicssectorSlider();
    maincatalogsectorSlider();
    accordionsubmenuInit();
    buttonmainInit();
    cookies();
    catalogfiltersInit();
    galleryInit();
    environmentalsectorSlider();
    initfileupload();
    checkboxtenderInit();
    catalogitemsectorSlider();
    infrastructuresectorSlider();
}
