import Swiper from 'swiper';
import {addZero} from '../modules/functions';

export function catalogadditionalmaterialsSlider() {
    const $s = $('.catalog-additional-materials-slider');

    if(!$s.length) return;

    const slider = new Swiper('.catalog-additional-materials-slider', {
        cssMode: true,
        navigation: {
            nextEl: '.catalog-additional-materials-slider-next',
            prevEl: '.catalog-additional-materials-slider-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: (number) => {
                return addZero(number);
            },
            formatFractionTotal: (number) => {
                return addZero(number);
            }
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1700: {
                slidesPerView: 3,
                spaceBetween: 40,
            }
        },
        mousewheel: false,
    });
}