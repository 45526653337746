export function filtersInit() {
    const $v = $('.parameters');
    const $it = $v.find('.parameters-content-name');

    $it.on('click', function () {
        const $c = $(this);


        if($c.is('.transform-icon')) {
            $c.removeClass('transform-icon');
            $('.parameters-content ul').stop(true, true).slideUp(300);

        } else {

            const $ti = $v.find('.parameters-content-name.transform-icon');

            $('.parameters-content ul').slideUp(300);
            $ti.removeClass('transform-icon');


            $c.addClass('transform-icon');
            $c.next('ul').slideDown(300);
        }
    });
}