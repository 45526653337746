import {mapStyles} from '../components';

export function contactMap() {
    const $m = $('#contact-map');

    if(!$m.length) return;

    const pos = new google.maps.LatLng(55.912657, 36.858388);
    const map = new google.maps.Map(
        document.getElementById('contact-map'),
        {
            zoom: 17,
            center: pos,
            styles: mapStyles
        },
    );

    const marker = new google.maps.Marker({
        position: pos,
        icon: $m.data('icon'),
        map: map,
        anchorPoint: new google.maps.Point(-43, -33)
    });
}

export function contactMapMobile() {
    const $m = $('#contact-map-mobile');

    if(!$m.length) return;

    const pos = new google.maps.LatLng(55.912657, 36.858388);
    const map = new google.maps.Map(
        document.getElementById('contact-map-mobile'),
        {
            zoom: 17,
            center: pos,
            styles: mapStyles
        },
    );

    const marker = new google.maps.Marker({
        position: pos,
        icon: $m.data('icon'),
        map: map,
        anchorPoint: new google.maps.Point(-43, -33)
    });
}

/*export function contactMapService() {
    const $m = $('#contact-map-service');

    if(!$m.length) return;

    const pos = new google.maps.LatLng(57.06450967683184, 60.36866242377609);
    const map = new google.maps.Map(
        document.getElementById('contact-map-service'),
        {
            zoom: 4.25,
            center: pos,
            styles: mapStyles
        },
    );


    const markers = [{id: 0, lat: 55.814062, lng: 37.784619, address: 'Москва, ул. Амурская, д. 15А'},
        {id: 1, lat: 55.487713, lng: 37.565425, address: 'Москва, Симферопольское ш., д. 22, стр. 9'},
        {id: 2, lat: 57.752352, lng: 40.88382, address: 'Кострома, ул. Московская, д. 92'},
        {id: 3, lat: 56.249757, lng: 43.990122, address: 'Нижний Новгород, ул. Героя Шапошникова, д. 15'},
        {id: 4, lat: 57.557502, lng: 39.821535, address: 'Ярославская обл., п. Щедрино, ул. Московская, стр. 6А'},
        {id: 5, lat: 50.534891, lng: 36.685193, address: 'Белгородская обл., пос. Разумное, ул. Бельгина, д. 9'},
        {id: 6, lat: 53.334623, lng: 34.226396, address: 'Брянск, ул. Шоссейная, д. 8, оф. 310'},
        {id: 7, lat: 51.668294, lng: 39.335771, address: 'Воронежская обл., Новоусманский р-н, пос. Отрадное, ул. Промышленная, д. 2'},
        {id: 8, lat: 56.886636, lng: 35.829161, address: 'Тверь, ул. Фрунзе, д. 1'},
        {id: 9, lat: 54.512413, lng: 36.301999, address: 'Калуга, 2-й Тульский переулок, д. 5'},
        {id: 10, lat: 51.652936, lng: 36.070503, address: 'Курск, ул. Обьездная, д. 7'},
        {id: 11, lat: 53.043239, lng: 36.167799, address: 'Орел, ул. Раздольная, д. 105'},
        {id: 12, lat: 56.162221, lng: 40.410165, address: 'Владимир, ул. Полины Осипенко, д. 66'},
        {id: 13, lat: 54.578009, lng: 39.822649, address: 'Рязань, Восточный промузел, стр.11, территория АТП «Разаньагровод»'},
        {id: 14, lat: 60.070828, lng: 30.378831, address: 'Санкт-Петербург, пр-т Культуры, д. 40, промзона «Парнас»'},
        {id: 15, lat: 64.484513, lng: 40.772132, address: 'Архангельск, ул. Силикатчиков, д. 19, стр. 1'},
        {id: 16, lat: 59.229042, lng: 39.930833, address: 'Вологда, ул. Гиляровского, д. 50'},
        {id: 17, lat: 61.816137, lng: 34.310523, address: 'Петрозаводск, ул. Заводская, д. 2'},
        {id: 18, lat: 57.850535, lng: 28.309084, address: 'Псков, Ваулиногорское ш.'},
        {id: 19, lat: 55.804536, lng: 49.277768, address: 'Казань, пос. Константиновка, ул. Мира, 34-Б'},
        {id: 20, lat: 54.831846, lng: 56.093915, address: 'Уфа, ул.Инициативная, 11/2'},
        {id: 21, lat: 58.504591, lng: 49.704054, address: 'Киров, ул. Советская, д. 51/2'},
        {id: 22, lat: 56.344865, lng: 43.89835, address: 'Нижний Новгород, ул. Коминтерна, д. 51'},
        {id: 23, lat: 57.952874, lng: 56.10835, address: 'Пермь, Шоссе Космонавтов, 312'},
        {id: 24, lat: 53.224288, lng: 44.941833, address: 'Пенза, ул. Рахманинова, д. 1, оф. 6'},
        {id: 25, lat: 53.512686, lng: 50.1424, address: 'Самара, пгт. Береза, территория аэропорта "Самара", промзона'},
        {id: 26, lat: 53.249537, lng: 50.199259, address: 'Самара, ул. Солнечная, 28'},
        {id: 27, lat: 51.428431, lng: 46.151091, address: 'Энгельс, пос. Пробуждение, ул. Придорожная, д. 10'},
        {id: 28, lat: 47.307547, lng: 39.646795, address: 'Ростовская область, Мясниковский район, х. Ленинакан, ул. Солнечная, 7б'},
        {id: 29, lat: 48.780297, lng: 44.490726, address: 'Волгоград, ул. Краснополянская, 23'},
        {id: 30, lat: 56.908325, lng: 60.629634, address: 'Екатеринбург, ул. Таганская, 60А'},
        {id: 31, lat: 56.881889, lng: 60.625611, address: 'Екатеринбург, ул. Фронтовых Бригад, территория Свердловэлектроремонт, ОАО'},
        {id: 32, lat: 57.157051, lng: 65.464628, address: 'Тюмень, ул. Авторемонтная, д. 12, стр. 1'},
        {id: 33, lat: 55.123765, lng: 61.492062, address: 'Челябинск, Копейское шоссе, 23'},
        {id: 34, lat: 55.983912, lng: 92.893131, address: 'Красноярск, ул. 60 лет Октября, д. 105'},];

    const mapMarkers = {};
    const infoWindows = {};

    markers.map((x) => {
        mapMarkers['id' + x.id] = new google.maps.Marker({
            position: new google.maps.LatLng(x.lat, x.lng),
            icon: '/assets/img/map-pin-service.png',
            map: map,
            anchorPoint: new google.maps.Point(-43, -33)
        });

        const popupContent =
            '<div class="gm-style">' +
            '<div class="content">' +
            '<div class="infobox__address">' +
            x.address +
            "</div>" +
            '<a href="#service" class="infobox__button button open-popup">' +
            "Записаться на сервис</a> " +
            '<a href="#spareparts" class="infobox__button button open-popup">' +
            "Заказать запчасти</a> " +
            "</div>" +
            "</div>";

        infoWindows['id' + x.id] = new google.maps.InfoWindow({
            content: popupContent,
        });

        mapMarkers['id' + x.id].addListener("click", () => {
            infoWindows['id' + x.id].open({
                anchor: mapMarkers['id' + x.id],
                map,
                shouldFocus: false,
            });
        });

        $('.service-contacts-table__icon').on('click', (e) => {
            const id = $(e.currentTarget).data('windowid');
            infoWindows['id' + id].open({
                anchor: mapMarkers['id' + id],
                map,
                shouldFocus: false,
            });

            $('html,body').stop(true, false).animate({
                scrollTop: $("#contact-map-service").offset().top
            }, 2000);
        });
    });
}*/

