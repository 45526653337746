export function checkboxtenderInit() {
    $('.material-selection-block-item').click(function() {
        if ($(this).hasClass('clicked')) {
            $(this).removeClass('clicked');
            $(this).find('.checkbox-wrapper').removeClass('checkbox-wrapper-active');
            $(this).find('.checkbox').removeClass('checkbox-active');
            $(this).find('.if-check').removeClass('if-active');
            let productId = $(this).data('id');
            $(`#product_id_${productId}`).remove();
            $(this).parent().append(this);
        } else {
            $(this).addClass('clicked');
            $(this).find('.checkbox-wrapper').addClass('checkbox-wrapper-active');
            $(this).find('.checkbox').addClass('checkbox-active');
            $(this).find('.if-check').addClass('if-active');
            let productId = $(this).data('id');
            $('<input/>').attr({
                    type: 'text',
                    name: `feedback[feedback_products_attributes][${productId}][product_id]`,
                    id: `product_id_${productId}`
                }).val(productId).appendTo('#new_feedback .product_fields');
            $(this).parent().prepend(this);
        }
    });
}