import Cookies from 'js-cookie'

export function cookies() {
    const $c = $('.button-cookies');
    const $l = $('.button-close-cookies');

    $c.on('click', function () {
        $('.cookies-popup').slideUp(300);
        Cookies.set('cookie-popup', 1, { expires: 7, path: '/' })
    });

    $l.on('click', function () {
        $('.cookies-popup').addClass('cookies-close');
    });
}
