import Swiper from 'swiper';
import {addZero} from '../modules/functions';

export function maincatalogsectorSlider() {
    const $s = $('.main-catalog-sector-slider');

    if(!$s.length) return;

    const slider = new Swiper('.main-catalog-sector-slider', {
        cssMode: true,
        navigation: {
            nextEl: '.main-catalog-sector-slider-next',
            prevEl: '.main-catalog-sector-slider-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: (number) => {
                return addZero(number);
            },
            formatFractionTotal: (number) => {
                return addZero(number);
            }
        },
        grid: {
            0: {
                rows: 1,
            },
            768: {
                rows: 2,
            },
        },
        breakpoints: {
            0: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 20,
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 40,
                slidesPerGroup: 2,
            },
            1200: {
                slidesPerView: 3,
                /*slidesPerColumn: 3,*/
                slidesPerGroup: 3,
                spaceBetween: 38,
            }
        },
        mousewheel: false,
    });
}