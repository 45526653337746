export function buttonmainInit() {
    const $v = $('.welcome-info');
    const $it = $v.find('.button-welcome');

    $it.on('click', function () {
        const $c = $(this);


        if($c.is('.transform-icon')) {
            $c.removeClass('transform-icon');
            $('.p-mobile').stop(true, true).slideUp(300);

        } else {

            const $ti = $v.find('.button-welcome.transform-icon');

            $('.p-mobile').slideUp(300);
            $ti.removeClass('transform-icon');

            $c.addClass('transform-icon');
            $c.next('.p-mobile').slideDown(300);
        }
    });
}