const animationTime = 500;
const $op = $('.open-popup');
let hiding = false;

function hidePopup($popup, $close) {
    hiding = true;

    $popup.addClass('fade-out');
    $('body').addClass('hiding-popup');

    setTimeout(() => {
        $popup.removeClass('fade-out show');
        $('body').removeClass('hiding-popup show-popup');
        $close.unbind('click.close-popup');
        $popup.unbind('click.outside');
        hiding = false;
    }, animationTime)
}

export function showPopup($popup) {
    const $close = $popup.find('.close');
    const $inner = $popup.find('.popup-inner');

    $popup.bind('click.outside', function (e) {
        if(!$(e.target).closest($inner).length && !hiding) {
            hidePopup($popup, $close);
            $("body").removeClass("body-popup");
        }
    });

    $('body').addClass('show-popup');
    $popup.addClass('show');
    $("body").addClass("body-popup");

    $close.bind('click.close-popup', function (e) {
        e.preventDefault();
        if(!hiding) hidePopup($popup, $close);
        $("body").removeClass("body-popup");
    });
}

export function initPopup() {

    $(document).on('click', '.open-popup', function (e) {
        e.preventDefault();
        const href = $(this).attr('href');
        const id = $(this).attr('id')
        const $popup = $(href);
        const $popupid = $(id);

        showPopup($popup);
        showPopup($popupid);
    });
}

window.showPopup = showPopup;
