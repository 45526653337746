export function headerInit() {
    const $v = $('.header-navigation-mobile');
    const $it = $v.find('.header-title');

    $(".burger-button").on('click', function () {
        if (!$v.is('.showed')) {
            $v.stop(true, true).slideDown(300);
            $v.addClass('showed');
            $('.catalog-menu-block').addClass("catalog-menu-block-overflow");
        } else {
            $v.slideUp(300);
            $v.removeClass('showed');
            $('.catalog-menu-block').removeClass("catalog-menu-block-overflow");
        }
    });

    $it.on('click', function () {
        const $c = $(this);


        if($c.is('.transform-icon')) {
            $c.removeClass('transform-icon');
            $('.ul-header-mobile').stop(true, true).slideUp(300);

        } else {

            const $ti = $v.find('.header-title.transform-icon');

            $('.ul-header-mobile').slideUp(300);
            $ti.removeClass('transform-icon');


            $c.addClass('transform-icon');
            $c.next('.ul-header-mobile').slideDown(300);
        }
    });
}


$(document).ready(function(){
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 120) {
            $(".site-header").addClass("site-header-scroll");
            $("body").addClass("body-scroll");
            /*$(".site-header").addClass("fixedHeader");*/
            $(".site-header-mobile").addClass("fixedHeaderMobile");
            /*$(".header-navigation").addClass("navigation-none");*/
            /*$(".header-top").addClass("header-top-none");
            $(".header-navigation").addClass("header-navigation-scroll");*/
            /*$(".phone-header").addClass("phone-scroll");
            $(".header-top").addClass("site-header-scroll");
            $(".button-header").addClass("button-scroll");
            $(".slogan-header").addClass("slogan-scroll");
            $(".header-top-main").addClass("header-top-main-scroll");
            $(".header-menu-mobile").addClass("header-menu-mobile-scroll");
            $(".controls-header").addClass("controls-header-scroll");
            $(".button-header-small").addClass("button-header-small-scroll");
            $(".form-mobile").addClass("form-scroll");
            $(".header-top-main-mobile").addClass("header-top-main-mobile-scroll");
            $(".header-top-main-mobile").removeClass("header-top-main-scroll");
            $(".burger-button-mobile").addClass("burger-button-mobile-scroll");*/
        } else {
            $(".site-header").removeClass("site-header-scroll");
            $("body").removeClass("body-scroll");
            /*$(".site-header").removeClass("fixedHeader");*/
            $(".site-header-mobile").removeClass("fixedHeaderMobile");
            /*$(".header-navigation").removeClass("navigation-none");*/
            /*$(".header-top").removeClass("header-top-none");
            $(".header-navigation").removeClass("header-navigation-scroll");*/
            /*$(".phone-header").removeClass("phone-scroll");
            $(".header-top").removeClass("site-header-scroll");
            $(".button-header").removeClass("button-scroll");
            $(".slogan-header").removeClass("slogan-scroll");
            $(".header-top-main").removeClass("header-top-main-scroll");
            $(".header-menu-mobile").removeClass("header-menu-mobile-scroll");
            $(".controls-header").removeClass("controls-header-scroll");
            $(".button-header-small").removeClass("button-header-small-scroll");
            $(".form-mobile").removeClass("form-scroll");
            $(".header-top-main-mobile").removeClass("header-top-main-mobile-scroll");
            $(".burger-button-mobile").removeClass("burger-button-mobile-scroll");*/
        }
    });
});