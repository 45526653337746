export function materialShow() {
  $('.load-more-tender').on('click', function() {
    $(this).addClass('invisible');
    $('.for-hide').removeClass('invisible');
    $('.hide-more-tender').removeClass('invisible');
  });

  $('.hide-more-tender').on('click', function() {
    $(this).addClass('invisible');
    $('.for-hide').addClass('invisible');
    $('.load-more-tender').removeClass('invisible');
  });
}
